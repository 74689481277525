import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import s from './SidebarMenu.module.scss';
import Hamburger from 'Assets/svg/hamburger.svg';
import Chevron from 'Assets/svg/chevron.svg';

const SidebarMenu = ({
    title,
    items,
    active,
}) => {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const hasClass = document.body.classList.contains('sidebarmenu-open');
        if(isOpen && !hasClass) {
            document.body.classList.add('sidebarmenu-open');
        } else if(!isOpen && hasClass) {
            document.body.classList.remove('sidebarmenu-open');
        }
    }, [isOpen]);

    const classes = classNames(
        s['SidebarMenu'],
        {[s['SidebarMenu--Open']]: isOpen},
        {[s['SidebarMenu--Closed']]: !isOpen},
    );

    if(_.isEmpty(items)) {
        return null;
    }

    const sanitizedTitle = sanitizeText(title);

    return (
        <div className={classes}>
            <button
                className={s['SidebarMenu__Toggle']}
                type="button"
                onClick={() => setIsOpen(!isOpen)}
            >
                <Hamburger />
                <span className={'sr-only'}>{t('sidebarMenu.showMenu')}</span>
                <span dangerouslySetInnerHTML={{__html: sanitizedTitle}} />
                <Chevron />
            </button>

            <ul className={s['SidebarMenu__List']}>
                {items.map((item, index) =>
                    <Item
                        key={index}
                        {...item}
                        active={active}
                    />
                )}
            </ul>
        </div>
    );
};

SidebarMenu.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    active: PropTypes.string,
};

SidebarMenu.defaultProps = {
    title: '',
    items: [],
    active: null,
};

const Item = ({
    id,
    title,
    url,
    children,
    active,
}) => {
    const {t} = useTranslation();

    const hasChildren = !!children.length;
    const isActive = active === id;

    const flat = (item) => {
        return item.children ? [item.id, ...item.children.map(flat)] : item.id;
    };
    const allSubIds = _.flattenDeep([id, ...children.map(flat)]);
    const defaultOpen = allSubIds.includes(active) || isActive;

    const [isOpen, setIsOpen] = useState(defaultOpen);

    const classes = classNames(s['SidebarMenu__Item'], {
        [s['SidebarMenu__Item--Open']]: isOpen,
        [s['SidebarMenu__Item--Closed']]: !isOpen,
        [s['SidebarMenu__Item--HasChildren']]: hasChildren,
        [s['SidebarMenu__Item--Active']]: isActive,
    });

    const sanitizedTitle = sanitizeText(title);

    return (
        <li className={classes}>
            <div className={s['SidebarMenu__Content']}>
                <a
                    className={s['SidebarMenu__Link']}
                    href={url}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />

                {hasChildren &&
                    <button
                        className={s['SidebarMenu__Expand']}
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span
                            className={'sr-only'}
                            dangerouslySetInnerHTML={{
                                __html: t('sidebarMenu.toggleChildren', {title: sanitizedTitle})
                            }}
                        />
                    </button>
                }
            </div>

            {hasChildren &&
                <ul className={s['SidebarMenu__List']}>
                    {children.map((item, index) =>
                        <Item
                            key={index}
                            {...item}
                            active={active}
                        />
                    )}
                </ul>
            }
        </li>
    );
};

Item.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    children: PropTypes.array,
    active: PropTypes.string,
};

Item.defaultProps = {
    id: '',
    title: '',
    url: '',
    children: [],
    active: null,
};

export default SidebarMenu;
