import React from 'react';
import PropTypes from 'prop-types';
import {sanitizeAdvanced} from 'utils/html';
import s from './PasswordProtected.module.scss';

const PasswordProtected = ({content}) => {
    const sanitizedContent = sanitizeAdvanced(content);

    return (
        <div className={s['PasswordProtected']}>
            <div
                className={s['PasswordProtected__Content']}
                dangerouslySetInnerHTML={{__html: sanitizedContent}}
            />
        </div>
    );
};

PasswordProtected.propTypes = {
    content: PropTypes.string,
};

PasswordProtected.defaultProps = {
    content: '',
};

export default PasswordProtected;
