import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Components from 'Components/ModulesComponents';
import s from './Modules.module.scss';

const Modules = ({components}) => {
    return (
        <div className={s['Modules']}>
            <div className={s['Modules__List']}>
                {components.map((component, index) => {
                    const prevComp = index > 0 ? components[index - 1] : null;
                    const nextComp = index < components.length - 1 ? components[index + 1] : null;
                    return (
                        <Item
                            key={index}
                            {...component}
                            prevComp={prevComp}
                            nextComp={nextComp}
                        />
                    );
                })}
            </div>
        </div>
    );
};

Modules.propTypes = {
    components: PropTypes.array.isRequired,
};

Modules.defaultProps = {
    components: [],
};

const Item = ({componentName, componentProps, prevComp}) => {
    const Component = Components[componentName];
    if (!Component) {
        console.warn(`Component ${componentName} is missing.`);
        return null;
    }

    const classes = classNames(
        s['Modules__Item'],
        s[`Modules__Item--${componentName}`],
    );

    const hideShell = componentName === 'DonationSlider' && prevComp?.componentName === 'FactsExplorer';

    return (
        <div className={classes}>
            <Component {...componentProps} isModule={true} hideShell={hideShell} />
        </div>
    );
};

Item.propTypes = {
    componentName: PropTypes.string.isRequired,
    componentProps: PropTypes.object,
    prevComp: PropTypes.object,
    nextComp: PropTypes.object,
};

Item.defaultProps = {
    componentName: '',
    componentProps: {},
    prevComp: null,
    nextComo: null,
};

export default Modules;