import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Base from 'Layouts/Base';
import GutenbergContent from 'Components/GutenbergContent';
import Documents from 'Components/Documents';
import SidebarMenu from 'Components/SidebarMenu';
import FeaturedImage from 'Components/FeaturedImage';
import Share from 'Components/Share';
import Modules from 'Components/Modules';
import PasswordProtected from 'Components/PasswordProtected';
import s from './Page.module.scss';

const Page = ({
    title,
    image,
    sidebarMenu,
    gutenbergContent,
    documents,
    share,
    modules,
    passwordProtected,
}) => {
    const sanitizedTitle = sanitizeText(title);

    const caption = _.get(image, 'caption', '');
    const sanitizedCaption = sanitizeText(caption);

    const sizes = [
        '(min-width: 1280px) 1092px',
        '100vw',
    ];

    const hasSidebarMenu = !_.isEmpty(sidebarMenu) && !_.isEmpty(_.get(sidebarMenu, 'items', []));

    const isLocked = !_.isEmpty(passwordProtected);

    const classes = classNames(
        s['Page'],
        {[s['Page--HasSidebar']]: hasSidebarMenu},
        {[s['Page--NoSidebar']]: !hasSidebarMenu},
    );

    return (
        <article className={classes}>
            <div className={s['Page__Wrap']}>
                <div className={s['Page__Container']}>
                    {!_.isEmpty(image) &&
                        <div className={s['Page__FeaturedImage']}>
                            <FeaturedImage {...image} />
                        </div>
                    }

                    <h1
                        className={s['Page__Title']}
                        dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                    />

                    <div className={s['Page__Content']}>
                        {isLocked ? (
                            <PasswordProtected {...passwordProtected} />
                        ) : (
                            <Fragment>
                                <GutenbergContent {...gutenbergContent} />

                                {!_.isEmpty(documents) &&
                                    <div className={s['Page__Documents']}>
                                        <Documents {...documents} />
                                    </div>
                                }

                                {!_.isEmpty(share) &&
                                    <div className={s['Page__Share']}>
                                        <Share {...share} />
                                    </div>
                                }
                            </Fragment>
                        )}
                    </div>

                    {hasSidebarMenu &&
                        <div className={s['Page__Sidebar']}>
                            {hasSidebarMenu &&
                                <SidebarMenu {...sidebarMenu} />
                            }
                        </div>
                    }
                </div>
            </div>

            {!_.isEmpty(modules) &&
                <Modules {...modules} />
            }
        </article>
    );
};

Page.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.object,
    sidebarMenu: PropTypes.object,
    gutenbergContent: PropTypes.object,
    documents: PropTypes.object,
    share: PropTypes.object,
    modules: PropTypes.object,
    passwordProtected: PropTypes.object,
};

Page.defaultProps = {
    title: '',
    image: {},
    sidebarMenu: {},
    gutenbergContent: {},
    documents: {},
    share: {},
    modules: {},
    passwordProtected: null,
};

export default Base(Page);
