import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import * as Components from 'Components/GutenbergComponents';
import s from './GutenbergContent.module.scss';

const GutenbergContent = ({components}) => {
    return (
        <div className={s['GutenbergContent']}>
            <div className={s['GutenbergContent__Blocks']}>
                {components.map((component, index) => (
                    <Item
                        key={index}
                        identifier={index}
                        {...component}
                    />
                ))}
            </div>
        </div>
    );
};

GutenbergContent.propTypes = {
    components: PropTypes.array.isRequired,
};

GutenbergContent.defaultProps = {
    components: [],
};

const Item = ({componentName, componentProps, content, identifier}) => {
    if (_.isEmpty(componentName)) {
        return null;
    }

    const Component = Components[componentName];

    const innerHTML = Component ? null : {__html: componentProps.innerHTML};

    if(Component === undefined && _.isEmpty(componentProps.innerHTML)) {
        console.warn(`Component "${componentName}" and innerHTML are missing`);
        return null;
    }

    const align = _.get(componentProps, 'attrs.align', '');

    const classes = classNames(
        s['GutenbergContent__Block'],
        s[`GutenbergContent__Block--${componentName}`],
        {[s[`GutenbergContent__Block--Align${_.upperFirst(align)}`]]: align},
    );

    // Possibility to pass a string or rendered react element directly
    if(!_.isEmpty(content)) {
        return (
            <div className={classes}>
                <div className={s['GutenbergContent__Content']}>
                    {content}
                </div>
            </div>
        );
    }

    return (
        <div className={classes}>
            {Component ? (
                <Component {...componentProps} identifier={identifier} />
            ) : (
                <div
                    className={s['GutenbergContent__Richtext']}
                    dangerouslySetInnerHTML={innerHTML}
                />
            )}
        </div>
    );
};

Item.propTypes = {
    componentName: PropTypes.string.isRequired,
    componentProps: PropTypes.object,
    identifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
};

Item.defaultProps = {
    componentName: '',
    componentProps: {},
    identifier: 0,
    content: null,
};

export default GutenbergContent;