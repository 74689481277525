import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Accordion from 'Components/Accordion';
import s from './Documents.module.scss';

const Documents = ({items}) => {
    return (
        <div className={s['Documents']}>
            {items.map((item, index) => (
                <div className={s['Documents__Accordion']} key={index}>
                    <Accordion
                        {...item}
                        modifier="Documents"
                        id={`documents-${index}`}
                    >
                        <ul className={s['Documents__List']}>
                            {item.items.map((doc, jIndex) => (
                                <Item {...doc} key={jIndex} />
                            ))}
                        </ul>
                    </Accordion>
                </div>
            ))}
        </div>
    );
};

Documents.propTypes = {
    items: PropTypes.array.isRequired,
};

Documents.defaultProps = {
    items: [],
};

const Item = ({url, name, description, size, extension}) => {
    const displaySize = !_.isEmpty(size) ? ` ${size}` : '';
    const displayExt = !_.isEmpty(extension) ? ` (${extension})` : '';
    return (
        <li className={s['Documents__Item']}>
            <a className={s['Documents__Link']} href={url} target="_blank" rel="noreferrer">
                <span className={s['Documents__LinkText']}>{name}</span>

                {(!_.isEmpty(extension) || !_.isEmpty(size)) &&
                    <span className={s['Documents__Meta']}>
                        {displayExt}
                        {displaySize}
                    </span>
                }

                {!_.isEmpty(description) &&
                    <span className={s['Documents__Description']}>{description}</span>
                }
            </a>
        </li>
    );
};

Item.propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    size: PropTypes.string,
    extension: PropTypes.string,
};

Item.defaultProps = {
    url: '',
    name: '',
    description: '',
    size: '',
    extension: '',
};

export default Documents;
