import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import ArrowLarge from 'Assets/svg/arrowLarge.svg';
import classNames from 'classnames';
import _ from 'lodash';
import { AnimateUpDown } from 'Components/Animate';
import s from './Accordion.module.scss';

const Accordion = ({title, url, children, open, id, anchor, modifier, changeHandler}) => {
    const {t} = useTranslation();

    const containerRef = useRef();
    const contentRef = useRef();

    // Open should be null if default states should be used
    const defaultOpen = open === true;
    const [isExpanded, setIsExpanded] = useState(defaultOpen);

    const headerId = `${id}-header`;
    const contentId = `${id}-content`;
    const anchorLink = !_.isEmpty(anchor) ? anchor : _.kebabCase(title);

    useEffect(() => {
        const hash = window?.location?.hash;
        if(!_.isEmpty(hash)) {
            if(`#${anchorLink}` === hash) {
                setIsExpanded(true);

                if(containerRef) {
                    setTimeout(() => {
                        containerRef.current.scrollIntoView({behavior: 'smooth'});
                    }, 500);
                }
            }
        }
    }, []);

    useEffect(() => {
        if(open !== isExpanded) {
            changeHandler(isExpanded);

            if(isExpanded) {
                const path = window.location.pathname + window.location.search;
                window.history.pushState({}, '', `${path}#${anchorLink}`);
            }
        }
    }, [isExpanded]);

    useEffect(() => {
        // Only change state if open forces a change
        if(open !== isExpanded && open !== null) {
            setIsExpanded(open);
        }
    }, [open]);

    const clickHandler = () => setIsExpanded(!isExpanded);

    const buttonText = isExpanded ? t('accordion.minimize') : t('accordion.expand');

    const classes = classNames(
        s['Accordion'],
        {[s['Accordion--Expanded']]: isExpanded},
        {[s[`Accordion--${modifier}`]]: modifier},
    );

    return (
        <div className={classes} ref={containerRef} id={anchorLink}>
            <div
                className={s['Accordion__Header']}
                onClick={clickHandler}
                aria-expanded={isExpanded}
                aria-controls={contentId}
                id={headerId}
            >
                <h2 className={s['Accordion__Title']}>
                    {url ? (
                        <a className={s['Accordion__Link']} href={url}>
                            <span className={s['Accordion__LinkArrow']}>
                                <ArrowLarge />
                            </span>
                            {title}
                        </a>
                    ) : (
                        <>{title}</>
                    )}
                </h2>
                <button className={s['Accordion__Button']}>
                    <span className={'sr-only'}>
                        {buttonText}
                    </span>
                </button>
            </div>
            <AnimateUpDown
                defaultVisible={defaultOpen}
                isVisible={isExpanded}
                childRef={contentRef}
            >
                <div
                    className={s['Accordion__Content']}
                    aria-hidden={!isExpanded}
                    aria-labelledby={headerId}
                    id={contentId}
                    ref={contentRef}
                >
                    {children}
                </div>
            </AnimateUpDown>
        </div>
    );
};

Accordion.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    children: PropTypes.node.isRequired,
    open: PropTypes.bool,
    id: PropTypes.string,
    anchor: PropTypes.string,
    modifier: PropTypes.string,
    changeHandler: PropTypes.func,
};

Accordion.defaultProps = {
    title: '',
    url: '',
    children: '',
    open: false,
    id: 'accordion',
    anchor: '',
    modifier: '',
    changeHandler: () => {},
};

export default Accordion;
