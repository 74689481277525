// Add any components that should be used in GutenbergContent
import dynamic from 'next/dynamic';

const AccordionList = dynamic(() => import('./AccordionList'));
const Contactlist = dynamic(() => import('./Contactlist'));
const CoreButtons = dynamic(() => import('./CoreButtons'));
const CoreEmbed = dynamic(() => import('./CoreEmbed'));
const Gravityforms = dynamic(() => import('./Gravityforms'));
const Snippet = dynamic(() => import('./Snippet'));

export {
    AccordionList,
    Contactlist,
    CoreButtons,
    CoreEmbed,
    Gravityforms,
    Snippet,
};