import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import s from './Share.module.scss';

const Share = ({
    text,
    url,
}) => {
    const {t} = useTranslation();

    const items = [
        {
            type: 'facebook',
            href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        },
        {
            type: 'twitter',
            href: `https://twitter.com/intent/tweet?url=${url}&text=${text}`
        },
        {
            type: 'linkedin',
            href: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
        }
    ];

    return (
        <div className={s['Share']}>
            <h2 className={s['Share__Title']}>{t('share.title')}</h2>
            <ul className={s['Share__List']}>
                {items.map((item, index) =>
                    <li className={s['Share__Item']} key={index}>
                        <Link {...item} />
                    </li>
                )}
            </ul>
        </div>
    );
};

Share.propTypes = {
    text: PropTypes.string,
    url: PropTypes.string,
};

Share.defaultProps = {
    text: '',
    url: '',
};

const Link = ({ type, href }) => {
    const {t} = useTranslation();

    const classes = classNames (
        s['Share__Link'],
        {[s[`Share__Link--${_.upperFirst(type)}`]]: type}
    );

    const handleClick = (e) => {
        e.preventDefault();
        const style = 'toolbar,scrollbars,resizable,top=100,left=100,width=800,height=500';
        window.open(href, '_blank', style);
    };

    return (
        <a
            href={href}
            className={classes}
            onClick={handleClick}
            target="_blank"
            rel="noreferrer"
        >
            <span className={'sr-only'}>{t('share.share', {type: type})}</span>
        </a>
    );
};

Link.propTypes = {
    type: PropTypes.string,
    href: PropTypes.string,
};

Link.defaultProps = {
    type: '',
    href: '',
};

export default Share;
