// Add any components that should be used in Modules
import dynamic from 'next/dynamic';

const ArticleSlider = dynamic(() => import('./ArticleSlider'));
const Billboard = dynamic(() => import('./Billboard'));
const BillboardCampaign = dynamic(() => import('./BillboardCampaign'));
const CalendarSlider = dynamic(() => import('./CalendarSlider'));
const CourseList = dynamic(() => import('./CourseList'));
const DonationSlider = dynamic(() => import('./DonationSlider'));
const ExpandableContent = dynamic(() => import('./ExpandableContent'));
const FactsExplorer = dynamic(() => import('./FactsExplorer'));
const HearingCareGuidance = dynamic(() => import('./HearingCareGuidance'));
const LinkList = dynamic(() => import('./LinkList'));
const NewsSlider = dynamic(() => import('./NewsSlider'));
const QuoteSlider = dynamic(() => import('./QuoteSlider'));
const VideoSlider = dynamic(() => import('./VideoSlider'));

export {
    ArticleSlider,
    Billboard,
    BillboardCampaign,
    CalendarSlider,
    CourseList,
    DonationSlider,
    ExpandableContent,
    FactsExplorer,
    HearingCareGuidance,
    LinkList,
    NewsSlider,
    QuoteSlider,
    VideoSlider,
};