import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './FeaturedImage.module.scss';

const FeaturedImage = ({
    keepProportion = false,
    image = {},
}) => {
    const caption = image?.caption;
    const width = image?.width;
    const height = image?.height;

    const sanitizedCaption = sanitizeText(caption);

    // Check if proportion should be kept or the image doesn't match the given area for image
    const showAsSmall = keepProportion || (width && width < 1092) || (width && height && height / width > 0.7);
    const maxWidth = showAsSmall ? 640 : 1092;

    const sizes = [
        showAsSmall ? '(min-width: 640px) 640px' : '(min-width: 1280px) 1092px',
        '100vw',
    ];

    // Make sure the maximum size of image allowed is used, but only needed if actually smaller
    const style = width && height && width < maxWidth ? {maxWidth: `${width}px`, maxHeight: `${height}px`} : {};

    const classes = classNames(
        s['FeaturedImage'],
        {[s['FeaturedImage--Small']]: showAsSmall},
        {[s['FeaturedImage--Large']]: !showAsSmall},
    );

    return (
        <figure
            className={classes}
            style={style}
        >
            <div className={s['FeaturedImage__Image']}>
                <Image
                    {...image}
                    sizes={sizes}
                    lazyLoad={false}
                    useCover={!showAsSmall}
                    priority={true}
                />
            </div>

            {!_.isEmpty(caption) &&
                <figcaption
                    className={s['FeaturedImage__Figcaption']}
                    dangerouslySetInnerHTML={{__html: sanitizedCaption}}
                />
            }
        </figure>
    );
};

FeaturedImage.propTypes = {
    keepProportion: PropTypes.bool,
    image: PropTypes.object,
};

export default FeaturedImage;
